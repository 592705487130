import React from 'react';

export const CenterLineIcon = (props: any) => {
  return (
    <div {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2"
        height="13"
        viewBox="0 0 2 13"
        fill="none"
      >
        <path d="M1 0.504761V12.5048" stroke="#F1F1F1" strokeOpacity="0.3" />
      </svg>
    </div>
  );
};
