import React, { useCallback } from 'react';
import { config } from '../../../archive.config';
import '../index.scss';
import './detail.scss';
import {
  archiveItemType,
  AuthorType,
} from '@/containers/History/HistoryLine/interface';
import { CenterLineIcon } from '../../containers/OngoingPlaytest/PlayItem/svgs/CenterLineIcon';

function DetailPage({ params }: any) {
  const { keyword } = params;
  let str = '';
  let title = '';
  let author: AuthorType[] = [];
  let time = '';
  let detailRecommendation = 0;

  const findItem: archiveItemType[] | undefined = config.filter(
    item => item.key === keyword
  );
  if (findItem && findItem[0]) {
    const item = findItem[0];
    str = item.htmlStr || '';
    title = item.title || item.title || '';
    author = item.author || [];
    // time = item.time || '-';
    detailRecommendation = item.recommendation || 0;
  }

  const RenderAuthors = useCallback(() => {
    if (author.length > 0) {
      return (
        <>
          {author.map((item, index) => {
            if (index === author.length - 1) {
              if (item.link) {
                return (
                  <a key={index} href={item.link} target={'_blank'}>
                    {item.name || 'Unknown'}
                  </a>
                );
              } else {
                return <p key={index}>{item.name || 'Unknown'}</p>;
              }
            } else {
              if (item.link) {
                return (
                  <div key={index} style={{ marginRight: '8px' }}>
                    <a href={item.link} target={'_blank'}>
                      {item.name || 'Unknown'}
                    </a>{' '}
                    and
                  </div>
                );
              } else {
                return (
                  <div key={index} style={{ marginRight: '8px' }}>
                    <p>{item.name || 'Unknown'}</p> and{' '}
                  </div>
                );
              }
            }
          })}
        </>
      );
    } else {
      return <p>Unknown</p>;
    }
  }, [author]);

  return (
    <div className="detailPage">
      <div className="topTitle">{title}</div>
      <div className="detailInfo">
        <div className="left">
          <div className="author">
            <RenderAuthors />
          </div>
          {/*<span>{time}</span>*/}
        </div>
        <div className="centerLine">
          <CenterLineIcon />
        </div>
        {detailRecommendation > 0 && (
          <div className="right">
            <div
              style={{ width: `${detailRecommendation}%` }}
              className="detailRecommendation"
            ></div>
          </div>
        )}
      </div>

      <div className="content" dangerouslySetInnerHTML={{ __html: str }}></div>
      <div className="footer">
        <div className="endLine" />
        <div className="endText">End</div>
        <div className="endLine" />
      </div>
    </div>
  );
}

export default DetailPage;
